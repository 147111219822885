const awscognito = {
  Auth: {
    region: "us-west-2",
    userPoolId: "us-west-2_3m786js8T",
    userPoolWebClientId: "3d1jh5c36r4ll2ftbq03argar9",
    authenticationFlowType: "CUSTOM_AUTH",
  },
};

export default awscognito;
